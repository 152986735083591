export const getLandingPageName = () => {
  return "landing";
};
export const getWellcomePageName = () => {
  return "welcome";
};
export const getBannerPageName = () => {
  return "banner";
};
export const getMemberPageName = () => {
  return "member";
};
export const getRolePageName = () => {
  return "role";
};
export const getPermissionModulePageName = () => {
  return "permissionModule";
};
export const getCategoryPageName = () => {
  return "category";
};
export const getEventPageName = () => {
  return "event";
};
export const getSettingsPageName = () => {
  return "settings";
};
export const getUserPageName = () => {
  return "user";
};
export const maxUploadFileMb = 100;
export const guestIdentityOption = [
  "visitor", "observer", "substitute", "member"
];
export const guestStatusOption = [
 "pending",
 "attended",
 "cancelled"
];
export const joinIndexOption = [
  {
    value: 0,
    name: "沒有興趣加入，純粹支持會友"
  },
  {
    value: 1,
    name: "未知意向"
  },
  {
    value: 2,
    name: "有興趣加入BNI"
  },
];
export const areaCodeOptions = [
  {
    value: "+1",
    name: "+1"
  },
  {
    value: "+60",
    name: "+60"
  },
  {
    value: "+61",
    name: "+61"
  },
  {
    value: "+63",
    name: "+63"
  },
  {
    value: "+65",
    name: "+65"
  },
  {
    value: "+66",
    name: "+66"
  },
  {
    value: "+81",
    name: "+81"
  },
  {
    value: "+86",
    name: "+86"
  },
  {
    value: "+852",
    name: "+852"
  },
  {
    value: "+853",
    name: "+853"
  },
  {
    value: "+886",
    name: "+886"
  },
];