import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

// components
import DebugLogger from "../../components/DebugLogger";
import TableSkeleton from "../../components/TableSkeleton";

// api
import { MemberApi } from "../../api/MemberApi";
import ReportExportButton from "../../components/ReportExportButton";

export default function List() {
  const { t } = useTranslation();
  const csvRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [exportPage, setExportPage] = useState(1);
  const [exportPageSize, setExportPageSize] = useState(-1);
  const [exportFilter, setExportFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(7);
  const [headers, setHeaders] = useState([
    {
      label: t("member.id"),
      key: "id",
    },
    {
      label: t("member.customer_num"),
      key: "customerNum",
    },
    {
      label: t("member.first_name"),
      key: "firstName",
    },
    {
      label: t("member.last_name"),
      key: "lastName",
    },
    {
      label: t("member.title"),
      key: "title",
    },
    {
      label: t("member.area_code"),
      key: "areaCode",
    },
    {
      label: t("member.phone_number"),
      key: "phoneNumber",
    },
    {
      label: t("member.profile_photo"),
      key: "photo",
    },
  ]);

  useEffect(() => {
    fetchExportData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.list(page, pageSize, filter);
      if (response) {
        setData(response.data);
        setTotal(response.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExportData = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.list(exportPage, exportPageSize, exportFilter);
      if (response) {
        setExportData(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewDetail = id => {
    navigate(`/members/${id}`);
  };

  const newMember = () => {
    navigate(`/members/create`);
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);

    let newFilter = filter.filter(item => item.field != "first_name, last_name");
    newFilter.unshift({ field: "first_name, last_name", value: searchStr });
    setFilter(newFilter);
  };

  const checkFilter = () => {
    let show = false;
    filter.forEach(item => {
      if (item.value) show = true;
    });

    return show;
  };

  const filterLabel = () => {
    let label = [];

    filter.forEach(item => {
      if (!item.value) return;

      if (item.field == "first_name, last_name") {
        label.push(
          `${t("layout.search_by_title")}: ${item.value}`,
        );
      }
    });

    return label.join(", ");
  };

  return (
    <Container maxWidth="false">

      <Card variant="outlined">
        <CardHeader
          title={<Translation>{t => <Box> {t("menu.members")} </Box>}</Translation>}
          action={
            <Box display={"flex"} gap={1}>
              {haveCreatePermission && (
                <>
                  <ReportExportButton
                    headers={headers}
                    csvRef={csvRef}
                    filename={"Member List.csv"}
                    csvData={exportData}
                  />
                  <Button
                    color="inherit"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={newMember}
                  >
                    <Translation>{t => t("member.new_member")}</Translation>
                  </Button>
                </>
              )}
            </Box>
          }
        />

        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              py: 1,
              gap: 2,
            }}
          >
            <Box>{checkFilter() && <Chip label={filterLabel()} />}</Box>
            <TextField
              variant="outlined"
              placeholder={t("layout.search_by_title")}
              onChange={event => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <Box display={"flex"}>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        search();
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Box>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <DebugLogger title="Members" data={data} hidden={true}></DebugLogger>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"5%"}>{t("member.id")}</TableCell>
                  <TableCell width={"5%"}>{t("member.profile_photo")}</TableCell>
                  <TableCell width={"10%"}>{t("member.name")}</TableCell>
                  <TableCell width={"10%"}>{t("member.title")}</TableCell>
                  <TableCell width={"10%"}>{t("member.category")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_by")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_at")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!data.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row, index) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ border: "1px solid #0000001f" }}
                            alt={`member_icon_${index}`}
                            src={row.photo}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>{row.firstName} {row.lastName}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.category}</TableCell>
                      <TableCell>{row.updatedBy}</TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
