import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// helper
import { checkPermission } from "../../../helper/PermissionHelper";
import { pageName } from "../../../reducers/commonSlice";
import { user } from "../../../reducers/userSlice";

// components
import ActionFooter from "../../../components/ActionFooter";
import FullScreenLoading from "../../../components/FullScreenLoading";
import GuestBaseInfo from "../../../components/event/guest/BaseInfo";

// api
import { EventApi } from "../../../api/EventApi";

// validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required(<Translation>{t => t("error.name_is_required")}</Translation>),
  phoneNumber: Yup.string()
    .required(<Translation>{t => t("error.phone_number_is_required")}</Translation>)
    .matches(/^[0-9+]*$/, {
      excludeEmptyString: true,
      message: <Translation>{t => t("error.invalid_format")}</Translation>,
    })
    .test(
      "minLength",
      <Translation>{t => t("error.phone_min_length")}</Translation>,
      val => val === undefined || val.length < 1 || val.length > 7,
    ),
  identity: Yup.string().required(
    <Translation>{t => t("error.identity_is_required")}</Translation>
  ),
});

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [errors, setErrors] = useState({});

  const requiredFields = {
    visitor: [
      "name",
      "phone",
      // "recommendedMemberId",
      "refererMemberId",
      "companyName",
      "guestProfession",
      "joinReason",
      "joinIndex",
    ],
    observer: [
      "name",
      "phone",
      // "recommendedMemberId",
      "refererMemberId",
      "guestProfession",
      "joinReason",
      "chapterName",
    ],
    substitute: [
      "name",
      "phone", 
      "substituteMemberId",
      // "recommendedMemberId",
    ],
  };

  const formik = useFormik({
    initialValues: {
      areaCode: "+852",
      identity: "visitor",
      name: "",
      phoneNumber: "",
      refererMemberId: "",
      recommendedMemberId: "",
      substituteMemberId: "",
      companyName: "",
      profession: "",
      guestProfession: "",
      joinReason: "",
      joinIndex: "",
      chapterName: "",
      status: "pending",
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setFullScreenLoading(true);
      const response = await EventApi.guestDetails(id);
      if (response) {
        formik.setValues(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const update = async () => {
    formik.setTouched({
      identity: true,
      name: true,
      phoneNumber: true,
    });

    // check required fields
    let errors = {};
    Object.keys(formik.values).forEach((key) => {
      if (requiredFields[formik?.values?.identity]?.includes(key)) {
        if (formik.values[key] === "") {
          console.log(formik.values[key]);
          errors[key] = t("common:required");
        }
      }
    });

    if (Object.keys(errors).length > 0) {
      console.log(errors);
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    formik.validateForm();

    if (!formik.isValid) {
      return;
    }

    const guest = {
      ...formik.values,
    };

    try {
      setFullScreenLoading(true);
      const success = await EventApi.guestUpdate(id, guest);
      if (success) {
        toast.success(t("layout.updated_successfully"));
        navigate(`/event/${formik.values.eventId}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const guestDeleteData = async () => {
    try {
      setFullScreenLoading(true);
      const success = await EventApi.guestDeleteData(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate(`/event/${formik.values.eventId}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onChangeIdentity = (event) => {
    // clear errors when type change
    setErrors({});

    // clear form values
    formik.setValues({
      ...formik.values,
      identity: event.target.value,
      refererMemberId: "",
      recommendedMemberId: "",
      substituteMemberId: "",
      companyName: "",
      profession: "",
      guestProfession: "",
      joinReason: "",
      joinIndex: "",
      chapterName: "",
    });
  }

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/event"}>{t("menu.event")}</Link>
        <Link to={`/event/${formik.values.eventId}`}>{t("event.event_details")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <GuestBaseInfo
          formik={formik}
          guestDeleteData={guestDeleteData}
          haveDeletePermission={haveDeletePermission}
          errors={errors}
          requiredFields={requiredFields}
          onChangeIdentity={onChangeIdentity}
        />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={update} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
